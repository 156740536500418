<div
  *ngIf="field"
  [formGroup]="form"
  [ngClass]="fieldClasses"
  [attr.data-control-type]="field.controlType.toLowerCase()"
>

  <label [for]="field.key" 
    class="form-label {{field.type}}"
    *ngIf="field.type !== 'hidden' && field.label && !field.noLabel"
    [ngClass]="{'form-check-label': field.controlType.toLowerCase() === 'checkbox'}">
    {{field.label | translate}}
  </label>

  <ng-container [ngSwitch]="field.controlType.toLowerCase()">
    <ng-container *ngSwitchCase="'input'">
      <div class="d-flex flex-column" [ngClass]="{'color-picker-wrapper': field.type === 'color'}">
        <input 
          class="form-control {{sizeClass}}" 
          [formControlName]="field.key"
          [placeholder]="field.placeholder" 
          [type]="field.type || 'text'"
          [id]="field.key"
          [disabled]="field.disabled"
          [readonly]="field.readonly" 
          [min]="field.min"
          [max]="field.max"
          multitenantTrimSpaces
          [ngClass]="{'color-input form-control-color': field.type === 'color', 'disabled-input': field.disabled}"
        />
      </div>
    </ng-container>

    
    <div class="d-flex flex-column" *ngSwitchCase="'file'">
      <elibrito-file-field [field]="field" [form]="form" [isValid]="isValid" (imageUploaded)="imageUploaded.emit($event)"></elibrito-file-field>
    </div>

    <ng-container  *ngSwitchCase="'date'">
      <div class="d-flex flex-column">
        <mat-form-field>
          
          <input matInput  class="form-control {{sizeClass}} ps-4"
          [matDatepicker]="picker" [formControlName]="dateField.key"  
          [id]="dateField.key" [readonly]="dateField.readonly" [min]="dateField.minDate">
          <mat-hint>{{dateField.dateFormatString(translate.currentLang)}}</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>
    
    <input *ngSwitchCase="'checkbox'"
      class="form-control form-check-input"
      [formControlName]="field.key"
      [id]="field.key"
      type="checkbox"
      [ngClass]="{'is-invalid': !isValid}"
      [placeholder]="field.placeholder"
      [readonly]="field.readonly"
    >

    <select *ngSwitchCase="'dropdown'"
      class="form-control {{sizeClass}}"
      [id]="field.key"
      [formControlName]="field.key"
      [attr.disabled]="field.readonly ? '' : null"
    >
      <option *ngIf="field.placeholder" value>{{field.placeholder | translate}}</option>
      <option *ngFor="let opt of field.options" [value]="opt.key">{{opt.value}}</option>
    </select>
    
    <ng-container *ngSwitchCase="'lazy-dropdown'">
      <elibrito-lazy-loading-select
        [id]="lazyDropdownField.key"
        [formControlName]="lazyDropdownField.key"
        [endpoint]="lazyDropdownField.endpoint"
        [loadSelectedElement]="lazyDropdownField.loadSelectedElement"
        [pageSizeQueryparam]="lazyDropdownField.pageSizeQueryparam"
        [displayKey]="lazyDropdownField.displayKey"
        [idKey]="lazyDropdownField.idKey"
        [imgKey]="lazyDropdownField.imgKey"
        [readonly]="lazyDropdownField.readonly"
        [disabled]="lazyDropdownField.disabled"
        [map]="lazyDropdownField.map"
        [multiple]="lazyDropdownField.multiple"
        [placeholder]="lazyDropdownField.placeholder"
      ></elibrito-lazy-loading-select>
    </ng-container>

    <textarea *ngSwitchCase="'textarea'" class="form-control {{sizeClass}}" [id]="field.key" [formControlName]="field.key"></textarea>

    <ckeditor *ngSwitchCase="'richtext'" class="form-control {{sizeClass}}" [editor]="Editor" [id]="field.key" [formControlName]="field.key" data="" [config]=""></ckeditor>
  </ng-container>

  <span *ngIf="field.helpText" class="text-muted">
    {{field.helpText | translate}}
  </span>

  <div class="error-message" *ngIf="!isValid">
    <ng-container *ngIf="hasError('required')">{{ 'errors.required' | translate:{value: field.label | translate} }}</ng-container>
    <ng-container *ngIf="hasError('max')">{{ 'errors.max' | translate:{value: field.label | translate} }} {{field.max}}</ng-container>
    <ng-container *ngIf="hasError('min')">{{ 'errors.min' | translate:{value: field.label | translate} }} {{field.min}}</ng-container>
    <ng-container *ngIf="hasError('minlength')">{{ 'errors.minLength' | translate:{value: field.label | translate} }} {{field.minLength}}</ng-container>
    <ng-container *ngIf="hasError('maxlength')">{{ 'errors.maxLength' | translate:{value: field.label | translate} }} {{field.maxLength}}</ng-container>
</div>
</div>
