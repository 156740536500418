import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { FieldControlService } from './field-control.service';
import { FieldBase } from './fields';
import { Observable, of } from 'rxjs';
import { UtilsService } from '@shared/services/utils.service';

@Component({
  selector: 'multitenant-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnChanges {
  @Input() title: string;
  @Input() groups: {
    [key: string]: {
      title?: string;
      fields: Array<FieldBase<string> | {fields: FieldBase<string>[]}> | null;
    }
  };
  @Input() populateEndpoint: string;
  @Input() saveEndpoint: string;
  @Input() formValidMap: (formValue: any) => Observable<any> = (formValue) => of(formValue);
  @Input() showActions = true;
  @Input() showCancel = false;
  @Input() saveLabel = 'Guardar';
  @Input() pk: string;
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() formSubmitError: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageUploaded = new EventEmitter<Blob>();
  groupNames: string[];
  form: UntypedFormGroup;
  errorMessage= '';

  constructor(
    private http: HttpClient,
    private qcs: FieldControlService,
    private utils: UtilsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groups?.currentValue && !this.utils.deepEqual(changes.groups?.currentValue, changes.groups?.previousValue)) {
      this.groupNames = Object.keys(this.groups || {});
      const mainGroup = this.groupNames.reduce((acc, groupName) => {
        const group = this.groups[groupName];
        let fields: FieldBase<string>[] = group.fields.reduce((result, current: any) => {
          if (current.fields) {
            return [...result, ...current.fields];
          }

          result.push(current);

          return result;
        }, []);
        fields = fields.sort((a, b) => a.order - b.order) as FieldBase<string>[];
        return {
          ...acc,
          [groupName]: this.qcs.toFormGroup(fields)
        };
      }, {});
      this.form = new FormGroup(mainGroup);
      this.form.valueChanges.subscribe((formValue) => {
        this.errorMessage = '';
        if(this.groupNames.length ===1){
          formValue = formValue[this.groupNames[0]];
          this.updateFieldVisibility(formValue);
        }
      });
    }
  }

  onSubmit() {
    if (this.form.valid) {
      let value: any;
      if (this.groupNames.length === 1) {
        // If there is only one group, we return the value of that group
        value = this.form.value[this.groupNames[0]];
      } else {
        value = this.form.value;
      }
      // We map the value to the given format by the Input, by default we return the value as is
      this.formValidMap(value).subscribe(value => {
        if (this.saveEndpoint) {
          if (this.pk) {
            this.http.patch(`${this.saveEndpoint}${this.pk}/`, value)
              .pipe(take(1))
              .subscribe({next: (response) => { this.formSubmit.emit(response)},
               error: (err) => { this.errorMessage = err.error['responseMessage'] } });
            return;
          } else {
            this.http.post(this.saveEndpoint, value)
              .pipe(take(1))
              .subscribe({next: (response) => { this.formSubmit.emit(response)},
              error: (err) => {
                 this.errorMessage = err.error['responseMessage'];
                 if(this.errorMessage === "User already exists"){
                     this.errorMessage = 'El usuario ya existe'
                 } 
                }});
          }
        } else {
          this.formSubmit.emit(value);
        }
      });
    }
  }

  onCancel(event: Event) {
    event.stopPropagation();
    this.cancel.emit(event);
  }
  private updateFieldVisibility(formValue: any): void {
    this.groupNames.forEach((groupName) => {
      const group = this.groups[groupName];
      group.fields.forEach((field: any) => {
        if (typeof field.visibilityCondition === 'function') {
          const isVisible = field.visibilityCondition(formValue);
          const control = this.form.get(`${groupName}.${field.key}`);
            if (!isVisible && control) {
            control.disable({ emitEvent: false });
            control.setValue('', { emitEvent: false });
            } else if (isVisible && control) {
            control.enable({ emitEvent: false });
            }
        }
      });
    });
  }
}
