<multitenant-base-modal>
  <div
    class="modal-title d-flex align-items-center mb-2 justify-content-center">
    <i class="fas fa-money-check-alt text-success small-icon"></i>
    <h4 class="ms-2 mb-0 custom-button">Detalle de la Transacción</h4>
  </div>

  <div class="body-container">
    <div class="tab-content" id="nav-tabContent"
      style="position: relative;top: 10px;">
      <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
        aria-labelledby="nav-home-tab">
        <div class="card colors-cards">
          <p class="tab-text-commerm">Datos del comercio</p>
          <div class="card-body">
            <div class="btn-container">
              <label class="text-commerm">Comercio:</label>
              <label
                class="text-rest">{{transactionDetails?.merchantName}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Ruc:</label>
              <label class="text-ruc">{{transactionDetails?.businessId}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Dirección:</label>
              <label
                class="text-rest">{{transactionDetails?.businessAddress}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Correo:</label>
              <label
                class="text-email">{{transactionDetails?.cardholderEmail}}</label>
            </div>
          </div>
        </div>
        <div class="card colors-cards">
          <p class="tab-text-commerm">Datos de la transacción</p>
          <div class="card-body">
            <div class="btn-container">
              <label class="text-commerm">Id de la Transacción:</label>
              <label class="text-saleId">{{transactionDetails?.saleId}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Canal:</label>
              <label
                class="text-channel">{{transactionDetails?.channel}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Adquirente:</label>
              <label
                class="text-acquirer">{{transactionDetails?.acquirer}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Nro.Transacción:</label>
              <label
                class="text-purchaseNumber">{{transactionDetails?.purchaseNumber}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Fecha y Hora:</label>
              <label class="text-transactionDate">{{
                transactionDetails?.transactionDate | date:
                'dd/MM/yyyy HH:mm:ss' }}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Monto:</label>
              <label class="text-orderAmount">
                {{ getCurrencySymbol(transactionDetails?.currency) }} {{ formatAmount(transactionDetails?.orderAmount, transactionDetails?.currency) }}
              </label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Metodo de Pago:</label>
              <label class="text-brand">{{transactionDetails?.brand}}</label>
            </div>
            <div class="btn-container">
              <label class="text-commerm">Estado:</label>
              <span [ngClass]="[getStatusClass(transactionDetails?.status),'no-background']">
                <label class="text-center-status">{{getStatusInSpanish(transactionDetails?.status)}}</label> </span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-outline-secondary"
      (click)="activeModal.close('Close click')">Cerrar</button>
   
      @if (isMerchantUser  && transactionDetails?.status !== 'VOIDED' && transactionDetails?.status !== 'DENIED') {
    <div class="btn-container">
      <button type="button" class="btn btn-outline-warning"
      (click)="onAccept()">Anular</button>
    </div>
    }
  </div>

</multitenant-base-modal>