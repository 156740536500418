import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateFilterService {
    
  private dateRangeSource = new BehaviorSubject<{ startDate: string; endDate: string } | null>(null);
  dateRange$ = this.dateRangeSource.asObservable();

  setDateRange(dateRange: { startDate: string; endDate: string }) {
    this.dateRangeSource.next(dateRange);
  }

  getDateRange() {
    return this.dateRangeSource.value;
  }

  resetDateRange() {
    this.dateRangeSource.next(null);
  }
  

}
