export enum UserType {
  ACQUIRER_ADMIN = 'acquirer-admin',
  ACQUIRER_OPERATOR = 'acquirer-operator',
  MERCHANT_ADMIN = 'merchant-admin',
  MERCHANT_OPERATOR = 'merchant-operator',

  transmitter= 'istransmitter',
}
  
  export class User {
    email: string;
    name: string;
    level: UserType;
    isAcquirerAdmin?: boolean;
    isMerchantAdmin?: boolean;
    istransmitter?: boolean;
    merchants?: merchant[];
    chosenMerchant?: merchant;
    isAcquirerOperator?: boolean;
    isMerchantOperator?: boolean;

  };
  export interface merchant {
    merchantId: string;
    name: string;
  }
  
  export interface Student {
    id: number;
    ask_eli: boolean;
    bio: string;
    birth_date: null;
    photo: null;
    campus: string;
    is_active: boolean;
    bookshelf: number;
    user: User
  }
  
  export interface UserCredentials {
    username: string;
    password: string;
  }
  