import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DetailsTransaction } from '@shared/models/modal-transaction.models';
import { User } from '@shared/models/user.models';
import { UserService } from '@shared/services/user.service';
import { TransactionServices } from 'src/app/tenant/transactions/services/transaction.service';
import { CurrencyService } from '@shared/services/currency.service';
import { AnullmentService } from '@shared/services/anullment.service';
import { AlertsService } from '@shared/services/alerts.service';
import { CurrencyPipe } from '@angular/common';
import { STATUS_CLASS_MAP_TRANSACTION, StatusTransaction } from '@shared/models/enum.models';

@Component({
  selector: 'multitenant-modal-detail-transaction',
  templateUrl: './modal-detail-transaction.component.html',
  styleUrl: './modal-detail-transaction.component.scss',

})
export class ModalDetailTransactionComponent implements OnInit {
  @Output() transactionAnnulled = new EventEmitter<void>();
  @Input() merchantId!: string;
  @Input() saleId!: string;
  transactionDetails!: DetailsTransaction;
  merchantAnullment!: DetailsTransaction;
  isMerchantUser: boolean = false;
  isAnulled: boolean = false;

  private userService = inject(UserService);
  public activeModal = inject(NgbActiveModal);
  private transactionServices = inject(TransactionServices);
  private currencyService = inject(CurrencyService);
  private anullmentService = inject(AnullmentService);
  private alertsService = inject(AlertsService);
  private currencyPipe = inject(CurrencyPipe);


  ngOnInit(): void {
    this.getTransactionDetails();
    this.userService.currentUser$.subscribe((user: User) => {
      this.isMerchantUser = user?.isMerchantAdmin || false;
    });
  }

  getTransactionDetails() {
    this.transactionServices.detailsTrasactionApi(this.merchantId, this.saleId)
      .subscribe(details => {
        this.transactionDetails = details;
     });
  }


  public getStatusClass(status: StatusTransaction): string {
    return STATUS_CLASS_MAP_TRANSACTION[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: StatusTransaction): string {
    const statusMap: { [key in StatusTransaction]: string } = {
      [StatusTransaction.AUTHORIZED]: "AUTORIZADO",
      [StatusTransaction.UNEXPECTED]: "DESCONOCIDO",
      [StatusTransaction.DENIED]: "DENEGADO",
      [StatusTransaction.VOIDED]: "ANULADO",
    };
    return statusMap[status];
  }


  getCurrencySymbol(currencyCode: string): string {
    return this.currencyService.getCurrencySymbol(currencyCode);
  }

  
  checkIfAnulled(merchant: DetailsTransaction): boolean {
    // Aquí asumimos que ya fue anulada
    return merchant?.status === 'VOIDED';
  }



  onAccept(): void {

    if (this.checkIfAnulled(this.merchantAnullment)) {
      this.alertsService.error('Esta transacción ya ha sido cancelada');
    } else {
      this.anullmentService.deleteAnullmente(this.merchantId, this.saleId)
        .subscribe({
          next: () => {
            this.isAnulled = true;
            this.alertsService.success('Transacción anulada con éxito.');
            this.transactionAnnulled.emit(); // event
            this.activeModal.close();
          },
          error: (err) => {
            console.error('Error canceling transaction:', err);
            this.alertsService.error('There was a problem canceling the transaction.');
          }
        });
    }
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }


}
