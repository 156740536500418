import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[multitenantTrimSpaces]'
})
export class TrimSpacesDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    // Reemplazar dos o más espacios consecutivos con un solo espacio.

    let formattedValue = inputElement.value.replace(/\s+/g, ' ');
    // Check if the input value is empty or consists only of spaces.

    if (!inputElement.value.trim()) {
       // Eliminar espacios al inicio y al final.
       formattedValue = formattedValue.trim();
    }
     // Actualizar el valor del input.
     inputElement.value = formattedValue;

     // Disparar el evento 'input' para que Angular detecte el cambio.
     inputElement.dispatchEvent(new Event('input'));
  }
}