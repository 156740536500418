import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { STATUS_CLASS_MAP, StatusUeno } from '@shared/models/enum.models';
import { RechargeDetails } from '@shared/models/recharge.models';
import { User } from '@shared/models/user.models';
import { AlertsService } from '@shared/services/alerts.service';
import { CurrencyService } from '@shared/services/currency.service';
import { UserService } from '@shared/services/user.service';
import { RechargeServices } from 'src/app/tenant/transactions/services/recharge.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-detail-recharge',
  templateUrl: './modal-detail-recharge.component.html',
  styleUrls: ['./modal-detail-recharge.component.scss']
})
export class ModalDetailRechargeComponent implements OnInit {
  @Output() transactionReversed = new EventEmitter<void>();
  @Input() transactionId!: string;
  @Input() externalId!: string;
  rechargeDetails!: RechargeDetails;
  isAcquiringUser: boolean = false;
  rechargeReversed!: RechargeDetails;
  isTransfer: boolean = false;

  private rechargeServices = inject(RechargeServices);
  private currencyService = inject(CurrencyService);
  public activeModal = inject(NgbActiveModal);
  private currencyPipe = inject(CurrencyPipe);
  private userService = inject(UserService);
  private alertsService = inject(AlertsService);

  ngOnInit(): void {
    this.getDetailsRecharge();
    this.userService.currentUser$.subscribe((user: User) => {
     this.isAcquiringUser = user?.isAcquirerAdmin || false;
    });
  }

  getDetailsRecharge(): void {
    this.rechargeServices.detailsRecharge(this.transactionId).
      subscribe(data => {
        this.rechargeDetails = data;
   });
  }

  getCurrencySymbol(currencyCode: string): string {
    return this.currencyService.getCurrencySymbol(currencyCode);
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }

  public getStatusClass(status: StatusUeno): string {
    return STATUS_CLASS_MAP[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: StatusUeno): string {
    const statusMap: { [key in StatusUeno]: string } = {
      [StatusUeno.AUTHORIZED]: "Autorizado",
      [StatusUeno.REVERSED]: "Anulado",
      [StatusUeno.DENIED]: "Denegado",

    };
    return statusMap[status];
  }

  translateOperation(operation: string): string {
    const operationLower = operation?.toLowerCase();
    if (operationLower === 'recharge') {
      return 'Recarga';
    }
    return operation;
  }

  
checkIfAnulled(refills: RechargeDetails): boolean {
  // Aquí asumimos que ya fue reembolsada
  return refills?.status === 'REVERSED';
}


  onAccept(): void {

    if (this.checkIfAnulled(this.rechargeReversed)) {
      this.alertsService.error('Esta transacción ya ha sido cancelada');
    } else {
      this.rechargeServices.getReversed(this.externalId)
        .subscribe({
          next: () => {
            this.isTransfer = true;
            this.alertsService.success('Transacción anulada con éxito.');
            this.transactionReversed.emit(); // event
            this.activeModal.close();
          },
          error: (err) => {
            console.error('Error canceling transaction:', err);
            this.alertsService.error('There was a problem canceling the transaction.');
          }
        });
    }
  }

}
