import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (!value) return null;
    const parts = value.split('/').map(Number);
    return { day: parts[0], month: parts[1], year: parts[2] }; // Assumes a 4-digit year
  }

  format(date: NgbDateStruct | null): string {
    if (!date) return '';
    const day = String(date.day).padStart(2, '0');
    const month = String(date.month).padStart(2, '0');
    const year = String(date.year).slice(-2); // Use only the last two digits
    return `${day}/${month}/${year}`;
  }
}