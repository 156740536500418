import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ColDef, GridOptions, GridReadyEvent, IDatasource, IGetRowsParams, SortChangedEvent } from 'ag-grid-community';
import { catchError, debounceTime, take, takeUntil } from 'rxjs/operators';
import { CatalogService } from '@shared/services/catalog.service';
import { DataTableService } from './data-table.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AG_GRID_LOCALE_EN } from './i18n/en';
import { AG_GRID_LOCALE_ES } from './i18n/es';
import { FormControl } from '@angular/forms';
import { UtilsService } from '@shared/services/utils.service';
import { RightsidebarService } from '@layouts/services/rightsidebar.service';
import { AgGridAngular } from 'ag-grid-angular';
import { FilterCategory, FilterColumns } from '@shared/models/filters.models';
import { InputSearch, InputSearchs, TableAction } from '@shared/models/data-table.models';
import { CsvService } from '@shared/services/csv.service';
import { AlertsService } from '@shared/services/alerts.service';

@Component({
  selector: 'multitenant-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  @Input() baseUrl: string = this.catalog.serverApi;
  @Input() endpoint!: string;
  @Input() searchs: InputSearchs[] = [];
  @Input() search: InputSearch = {
    field: '',
    placeholder: '',
    show: false
  };
  @Input() searchNumber: InputSearch = {
    field: '',
    placeholder: '',
    show: false
  };
  @Input() dateRange: InputSearch = {
    field: '',
    placeholder: '',
    show: false
  };
  @Input() title: string;
  @Input() description: string;
  @Input() defaultOrderBy: {
    field: string;
    type: 'asc' | 'desc';
  };
  @Input() defaultFiltersComponent: string;

  @Input() columnDefs!: ColDef[];
  // filters right sidebar
  @Input() filterCategories!: FilterCategory[];

  @Input() defaultColDef: ColDef = {};
  @Input() gridOptions: GridOptions = {};
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() rowsAmount: number = 0;

  @Input() importFilename: string;
  @Input() importEndpoint: string;
  @Input() importModelEndpoint: string;
  @Input() importModel: any;
  @Input() importSuccessProperty = "success";
  @Input() resultsCustomMessageCallback: (data: any) => string;
  @Input() importLabelToken = 'generic.import';

  @Input() externalActions: TableAction[] = [];
  @Input() detailUrl: string | string[];
  // right sidebar logic
  filteredColumns: ColDef[];

  searchControl = new FormControl();
  searchNumberSerie = new FormControl();


  filterValue;

  queryFilters = {
    page: 1,
    limit: this.pageSize,
    criteria: []
  }

  public rowSelection: "single" | "multiple" = "multiple";
  showTable = true;
  localeText: {
    [key: string]: string;
  } = AG_GRID_LOCALE_ES;

  _gridOptions: GridOptions;
  _defaultColDef: ColDef;
  _columns: FilterColumns[];

  dateRangeConfig = {
    containerClass: 'theme-default',
    locale: 'es',
    rangeInputFormat: 'DD/MM/YYYY',
  }

  // Variable para almacenar los datos de la tabla
  tableData: any[] = [];

  get dataUrl(): string {
    return `${this.baseUrl}/${this.endpoint}`;
  }

  private readonly destroy$ = new Subject<void>();


  constructor(
    private dataTableService: DataTableService,
    private utils: UtilsService,
    private catalog: CatalogService,
    private translate: TranslateService,
    private rightsidebarService: RightsidebarService,
    private csvService: CsvService, 
    private alerts: AlertsService  

  ) { }

  ngOnInit(): void {
    if (this.defaultFiltersComponent) {
      this.queryFilters.criteria.push(this.defaultFiltersComponent);
    }
    this.changeLang(this.translate.currentLang);
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((event: LangChangeEvent) => this.changeLang(event.lang));

    
    this.searchControl.valueChanges.pipe(
      debounceTime(1000),
      takeUntil(this.destroy$)
    ).subscribe((searchTerm: string) => {
      if (searchTerm) {
        const trimmedValue = searchTerm.replace(/^\s+/, '');
        if (searchTerm !== trimmedValue) {
          this.searchControl.setValue(trimmedValue, { emitEvent: false });
        }
        this.updateCriteria(this.search.field, 'like', '%' + searchTerm + '%');
      } else {
        this.cleanCriteria(this.search.field);
      }
        
      this.refreshData();
    });
    this.initSearchsFormsControls();

    this.filterNumberSerie();


    if (!this.utils.isEmpty(this.columnDefs)) {
      this.filteredColumns = this.columnDefs;
      if (this.detailUrl) {
        this.columnDefs.push({
          field: 'actions',
          headerName: 'generic.actions',
          cellRenderer: 'linkCellRenderer',
          cellRendererParams: {
            link: this.detailUrl,
            iconClass: 'fa fa-eye',
            label: this.translate.instant('generic.viewDetails'),
          },
          minWidth: 150,
        });
      }

      this.columnDefs.forEach((colDef: ColDef) => {
        if (colDef.headerName) {
          colDef.headerName = this.translate.instant(colDef.headerName);
        }
      });
    }

    this._defaultColDef = {
      ...this.dataTableService.defaultColDef,
      ...this.defaultColDef,
    };

    this._gridOptions = {
      ...this.dataTableService.gridOptions,
      ...this.gridOptions,
      context: {
        componentParent: this
      }
    };

    this._columns = this.columnDefs.map((col) => {
      return {
        field: col.field,
        name: col.headerName,
        checked: false,
        disabled: false,
      };
    });

    this.rightsidebarService.rightviewSelectFilters$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data.view === 'columns') {
        if (data.filters.length) {
          this.filteredColumns = this.columnDefs.filter((col) => data.filters.some((f) => f.field === col.field));
        } else {
          this.filteredColumns = this.columnDefs;
        }
      } if (data.view === 'filters') {
        if (data.filters.length) {
          for (const iterator of data.filters) {
            this.updateCriteria(iterator.field, 'in', iterator.value.join(','));
          }
        } else {
          for (const filter of this.filterCategories) {
            this.cleanCriteria(filter.field)
          }
        }
      }
      this.refreshData();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.baseUrl || changes.endpoint) {
      this.refreshGrid();
    }
  }

  refreshData() {
    this.agGrid.api!.refreshInfiniteCache();
  }


 filterNumberSerie():void {
  this.searchNumberSerie.valueChanges.pipe(
    debounceTime(1000),
    takeUntil(this.destroy$)
  ).subscribe((searchTerm: string) => {
    if (searchTerm) {
      const trimmedValue = searchTerm.replace(/^\s+/, '');
      if (searchTerm !== trimmedValue) {
        this.searchNumberSerie.setValue(trimmedValue, { emitEvent: false });
      }
      this.updateCriteria(this.searchNumber.field, 'like', '%' + searchTerm + '%');
    } else {
      debounceTime(1000),
      this.cleanCriteria(this.searchNumber.field);
    }
      
    this.refreshData();
  });
 }
  // }

  onGridReady(event: GridReadyEvent) {
    let datasource: IDatasource = {
      getRows: (params: IGetRowsParams) => {
        const { startRow, endRow } = params;
        const pageSize = endRow - startRow;
        const currentPage = Math.floor(startRow / pageSize) + 1;
        this.queryFilters = { ...this.queryFilters, page: currentPage, limit: pageSize }
        event.api!.showLoadingOverlay();
        this.dataTableService.getData(this.dataUrl, this.queryFilters, this.defaultOrderBy)
          .pipe(
            take(1),
            catchError((error: HttpErrorResponse) => {
              event.api!.hideOverlay();
              params.failCallback();
              this.alerts.handleHttpError(error);
              return null;
            })
          )
          .subscribe(data => {
            const results = data?.list ?? [];
            event.api!.hideOverlay();
            if (results.length === 0) {
              event.api!.showNoRowsOverlay();
            }
            params.successCallback(results, data?.total ?? 0);

            // Almacena los datos obtenidos en la variable tableData
            this.tableData = results;
          });
      },
    };
    event.api!.setDatasource(datasource);
  }
  removeColumnDefs() {
    this.refreshGrid();
  }

  refreshGrid() {
    // NOTE: This is a workaround to force ag-grid to refresh,
    // check in the future if there is a better way
    this.showTable = false;
    setTimeout(() => this.showTable = true);
  }

  private changeLang(lang: string) {
    switch (lang) {
      case 'en':
        this.localeText = AG_GRID_LOCALE_EN;
        break;
      case 'es':
        this.localeText = AG_GRID_LOCALE_ES;
        break;
      default:
        this.localeText = AG_GRID_LOCALE_ES;
    }
    this.refreshGrid();
  }
  showRightsidebar(view: string) {
    this.rightsidebarService.emitRightView({
      view: view,
      columns: this._columns,
      filters: this.filterCategories
    });
  }

  filterRangeDates($event) {
     if ($event && $event[0] && $event[1]) {
      this.updateCriteria(this.dateRange.field, 'btw', `${this.formatDate($event[0], '00', '00', '00')},${this.formatDate($event[1], '23', '59', '59')}`);
    } else {
      this.cleanCriteria(this.dateRange.field);
    }
    this.refreshData();
  }

  private updateCriteria(field: string, operator: string, value: string | number) {
    const existingCriteria = this.queryFilters.criteria.find((c) => c.field === field);
    if (existingCriteria) {
      existingCriteria.operator = operator;
      existingCriteria.value = value;
    } else {
      this.queryFilters.criteria.push({ field, operator, value });
    }
  }

  private cleanCriteria(field) {
    this.queryFilters.criteria = this.queryFilters.criteria.filter((c) => c.field !== field);
  }

  private formatDate(dateString, hour, minute, second) {
    const date = new Date(dateString);
    console.log(date);
    
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }

  // Método para descargar el archivo csv
  downloadCsv() {
    if (this.tableData && this.tableData.length > 0) {

      this.csvService.downloadCsv(
        this.tableData,
        'data_export',
        (event) => {
          this.alerts.success('¡Descarga exitosa!');
        },
        this.columnDefs,
        this.filterRangeDates // Pasa el filtro de rango de fechas
      );
    } else {
      this.alerts.error('No hay datos para descargar');
    }
  }

  private initSearchsFormsControls() {
    this.searchs.forEach(search => {
      search.control = new FormControl();
      search.control.valueChanges.pipe(
        debounceTime(1000),
        takeUntil(this.destroy$)
      ).subscribe((searchTerm: any) => {
        if (searchTerm) {
          if(search.type === 'text'){
            const trimmedValue = searchTerm.replace(/^\s+/, '');
            if (searchTerm !== trimmedValue) {
              search.control.setValue(trimmedValue, { emitEvent: false });
            }
            this.updateCriteria(search.field, 'like', '%' + searchTerm + '%');
          }
          else if(search.type === 'date'){
            if (searchTerm && searchTerm.day && searchTerm.month && searchTerm.year) {
              let { day, month, year } = searchTerm;
                if (year < 100) {
                year += 2000;
                }
              const date = new Date(year, month - 1, day);
              this.updateCriteria(search.field, 'btw',`${this.formatDate(date, '00', '00', '00')},${this.formatDate(date, '23', '59', '59')}` );
            }
          }
        } else {
          this.cleanCriteria(search.field);
        }
          
        this.refreshData();
      });
    });
  }
  onSortChanged(event: SortChangedEvent): void {
    const allColumns = event.api.getColumns();
    const sortedColumns = allColumns.find(col => col.getSort() !== undefined);
    if(sortedColumns){
      this.defaultOrderBy = {
        field: sortedColumns.getColId(), 
        type: sortedColumns.getSort()
      }
    }
    this.refreshData();
  }

}
