import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SimplebarAngularModule } from 'simplebar-angular';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import {NgClickOutsideDirective, NgClickOutsideExcludeDirective} from 'ng-click-outside2';

//import { UiLibraryModule } from '@ui_library/ui_library.module';
import { LayoutComponent } from './layout.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { HorizontalComponent } from './horizontal/horizontal.component';
import { VerticalComponent } from './vertical/vertical.component';
import { HorizontaltopbarComponent } from './horizontaltopbar/horizontaltopbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';



const layoutComponents = [
  LayoutComponent,
  TopbarComponent,
  FooterComponent,
  RightsidebarComponent,
  HorizontalComponent,
  VerticalComponent,
  HorizontaltopbarComponent,
  SidebarComponent,
  HeaderComponent
 ]

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    ...layoutComponents,
 
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    NgClickOutsideDirective,
    NgClickOutsideExcludeDirective,
    SimplebarAngularModule,
    NgbTooltipModule,
    SharedModule
  ],
  exports: [
    ...layoutComponents,
    HeaderComponent
    
  ],
})
export class LayoutsModule {
}
