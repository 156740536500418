<div class="ps-4">
  <h2 *ngIf="title">
    {{title}}
  </h2>
  <form *ngIf="groups && form" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div *ngFor="let key of groupNames;" [formGroupName]="key" class="row">
      <ng-container *ngIf="groups[key] as group;">
        <h3 *ngIf="group.title" class="my-3 border-top-1">{{group.title | translate}}</h3>
        <div class="row">
          <div *ngFor="let field of group.fields" class="col-lg-{{field.width}} col-12 col-md-6 {{field.type}}" [ngClass]="field.classes">
            <!-- Support grouping by fields -->
            <ng-container *ngIf="field.fields">
              <div *ngFor="let subField of field.fields" class="col-md-{{subField.width}} col-12">
                <div class="form-group mb-3">
                  <elibrito-dynamic-form-field [field]="subField" [form]="form.controls[key]" (imageUploaded)="imageUploaded.emit($event)"></elibrito-dynamic-form-field>
                </div>
              </div>
            </ng-container>
            <div *ngIf="!field.fields" class="form-group mb-3 {{field.type}}">
              <elibrito-dynamic-form-field [field]="field" [form]="form.controls[key]" (imageUploaded)="imageUploaded.emit($event)"></elibrito-dynamic-form-field>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <ngb-alert *ngIf="errorMessage" type="danger" class="text-center login-error-alert" (closed)="errorMessage = ''" >
      {{errorMessage}}
    </ngb-alert>
    <div *ngIf="showActions" class="justify-content-end" >
      <div class="col-12 col-md-12 padding-button d-flex justify-content-end">
        <button type="submit" class="btn btn-primary m-1" [disabled]="!form.valid">{{ saveLabel | translate}}</button>
        <button *ngIf="showCancel" type="button" class="btn btn-secondary m-1" (click)="onCancel($event)">{{'generic.cancel' | translate}}</button>
      </div>
    </div>
  </form>  
</div>
