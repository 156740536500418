import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CheckboxField, DateField, DropdownField, FieldBase, InputField, LazyDropdownField, RadioField, RichTextField } from './fields';

@Injectable({
  providedIn: 'root'
})
export class FieldControlService {
  constructor() { }

  toFormGroup(fields: FieldBase<string>[]): UntypedFormGroup {
    const group: any = {};
    fields.forEach(field => {
      const value = field.controlType === 'file' ? '' : field.value;
      const validators = [];

      if (field.required) {
        validators.push(Validators.required);
      }

      if (field.min !== undefined) {
        validators.push(Validators.min(field.min));
      }

      if (field.max !== undefined) {
        validators.push(Validators.max(field.max));
      }

      if (field.minLength !== undefined) {
        validators.push(Validators.minLength(field.minLength));
      }

      if (field.maxLength !== undefined) {
        validators.push(Validators.maxLength(field.maxLength));
      }

      if (field.type === 'email') {
        validators.push(Validators.email);
      }

      group[field.key] = new UntypedFormControl(value, validators);

      if (field.controlType === 'file') {
        group[field.key + 'Source'] = new UntypedFormControl('');
      }
    });

    return new UntypedFormGroup(group);
  }

  toFieldBase(fields: any[]): FieldBase<string>[] {
    const formFields: FieldBase<string>[] = fields.map((field, index) => {
      const commonFields = {
        key: field.field,
        label: field.label,
        required: field.required,
        order: index + 1,
      };
      delete field.field;
      delete field.label;
      delete field.required;

      const properties = {...commonFields, ...field};

      switch (field.type) {
        case 'date':
          return new DateField({ ...properties, type: 'date' });
        case 'richText':
          return new RichTextField(properties);
        case 'lazy-dropdown':
          return new LazyDropdownField(properties);
        case 'dropdown':
          return new DropdownField(properties);
        case 'checkbox':
          return new CheckboxField(properties);
        case 'radio':
          return new RadioField(properties);
        default:
          return new InputField({ ...commonFields, ...field, type: field.type });
      }
    });

    return formFields.sort((a, b) => a.order - b.order);
  }
}
