<multitenant-base-modal>
    <div
      class="modal-title d-flex align-items-center mb-2 justify-content-center">
    <i class="fas fa-user text-success small-icon"></i>
      <h4 class="ms-2 mb-0 custom-button">Detalle del usuario</h4>
    </div>
  
    <div class="body-container">
      <div class="tab-content" id="nav-tabContent" style="position: relative;top: 10px;">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <div class="card colors-cards">
            <p class="tab-text-commerm">Datos del Usuario</p>
            <div class="card-body">
              <div class="btn-container">
                <label class="text-commerm m-2">Nombre de usuario:</label>
                <label *ngIf="userdetails?.fullName" [ngbTooltip]="userdetails?.fullName">
                  {{userdetails?.fullName | slice:0:30}}{{userdetails?.fullName.length > 30 ? '...' : ''}}
                </label>
              </div>
              <div class="btn-container">
                <label class="text-commerm m-2">Correo:</label>
                <label class="text-email ">{{userdetails?.email}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm m-2">Perfil:</label>
                <label class="text-role ">{{getRoleTranslate(userdetails?.role)}}</label>
              </div>
              <div class="btn-container">
                <label class="text-commerm m-2">Estado:</label>
                <span [ngClass]="[getStatusClass(userdetails?.status),'no-background']">
                  <label class="text-center-status ">{{getStatusTranslate(userdetails?.status)}}</label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal-footer justify-content-center">
      <button type="button" class="btn btn-outline-secondary"
        (click)="activeModal.close('Close click')">Cerrar</button>
     
       
    </div>
  
  </multitenant-base-modal>

