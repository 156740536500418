import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RechargeDetails } from '@shared/models/recharge.models';
import { CurrencyService } from '@shared/services/currency.service';
import { RechargeServices } from 'src/app/tenant/transactions/services/recharge.service';
import { CurrencyPipe } from '@angular/common';
import { STATUS_CLASS_MAP, StatusUeno } from '@shared/models/enum.models';


@Component({
  selector: 'app-modal-detail-sales',
  templateUrl: './modal-detail-sales.component.html',
  styleUrls: ['./modal-detail-sales.component.scss']
})
export class ModalDetailSalesComponent implements OnInit {
  @Input() transactionId!: string;
  salesDetails!: RechargeDetails;


  private rechargeServices = inject(RechargeServices);
  private currencyService = inject(CurrencyService);
  private currencyPipe = inject(CurrencyPipe);
  public activeModal = inject(NgbActiveModal);


  ngOnInit(): void {
    this.getDetailsRecharge();
  }

  getDetailsRecharge(): void {
    this.rechargeServices.detailsRecharge(this.transactionId).
      subscribe(data => {
        this.salesDetails = data;
      });
  }

  getCurrencySymbol(currencyCode: string): string {
    return this.currencyService.getCurrencySymbol(currencyCode);
  }

  formatAmount(value: number, currency: string): string {
    if (currency === 'PYG') {
      return parseFloat(value.toString()).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return this.currencyPipe.transform(value?.toString(), '', '', '1.2-2');
  }

  public getStatusClass(status: StatusUeno): string {
    return STATUS_CLASS_MAP[status] || "badge-soft-primary";
  }

  public getStatusInSpanish(status: StatusUeno): string {
    const statusMap: { [key in StatusUeno]: string } = {
      [StatusUeno.AUTHORIZED]: "Autorizado",
      [StatusUeno.REVERSED]: "Invertido",
      [StatusUeno.DENIED]: "Denegado",

    };
    return statusMap[status];
  }

  translateOperation(operation: string): string {
    const operationLower = operation?.toLowerCase();
    if (operationLower === 'sale') {
      return 'Ventas';
    }
    return operation;
  }

}
