<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-4">
        <h4>{{title}}</h4>
        <p>{{description}}</p>
      </div>
      <div class="col-12 col-md-8 text-md-end text-center">
        <ng-container *ngIf="externalActions.length > 1 else singleAction">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-rounded btn-primary btn-label dropdown-button" id="action-list"
              ngbDropdownToggle>
              <i class="mdi mdi-widgets-outline label-icon"></i>
              {{'generic.actions' | translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="action-list" #dropdownMenu>
              @for (action of externalActions; track $index) {
              <ng-container>
                <ng-container *ngTemplateOutlet="actionType; context: {action : action}"></ng-container>
              </ng-container>
              }
            </div>
          </div>
        </ng-container>

        <ng-template #singleAction>
          @if (externalActions.length === 1) {
          <ng-container>
            <ng-container *ngTemplateOutlet="actionType; context: {action : externalActions[0]}"></ng-container>
          </ng-container>
          }
        </ng-template>

        <ng-template let-action="action" #actionType>
          @if (action.type === 'button') {
          <button *ngIf="" type="button" class="btn btn-rounded btn-{{ action.color ?? 'primary'}} btn-label mx-1"
            (click)="action.action($event)">
            <i class="label-icon" [class]="action.iconClass"></i>
            <span class="ms-1">{{ action.label | translate }}</span>
          </button>
          }

          @if (action.type === 'link') {
          <ng-container>
            @if (action.link) {
            <ng-container>
              <a [routerLink]="action.link" class="btn btn-rounded btn-primary w-sm" [title]="action.label | translate">
                <i [class]="action.iconClass"></i>
                <span class="ms-1">{{ action.label | translate }}</span>
              </a>
            </ng-container>}
            @if (action.externalLink) {
            <ng-container>
              <a [href]="action.externalLink" class="btn btn-rounded btn-primary w-sm"
                [title]="action.label | translate">
                <i [class]="action.iconClass"></i>
                <span class="ms-1">{{ action.label | translate }}</span>
              </a>
            </ng-container>}
          </ng-container>}

        </ng-template>
      </div>
    </div>
    
    <div class="row">
      @if (search.show) {
        <div class="col-12 col-md-3 mb-3">
          <div class="mb-3">
            <form class="app-search d-lg-block">
              <div class="position-relative">
                <input 
                  type="search" 
                  class="form-control" 
                  placeholder="{{search.placeholder}}"
                  [formControl]="searchControl"
                 
                >
                <span class="bx bx-search-alt"></span>
              </div>
            </form>
          </div>
        </div>
      }
      @if(searchs.length){
        @for (searchInput of searchs; track $index) {
            <div class="col-12 col-md-3 mb-3">
              <div class="mb-3">
                <form class="app-search d-lg-block">
                  <div class="position-relative">
                    @if (searchInput.type === 'text') {
                      <input 
                        type="text" 
                        class="form-control" 
                        [placeholder]="searchInput.placeholder"
                        [formControl]="searchInput.control"
                      >
                      <span class="bx bx-search-alt"></span>
                    }
                    @if (searchInput.type === 'date') {
                        <input 
                        type="text" 
                        class="form-control" 
                        [placeholder]="searchInput.placeholder"
                        [formControl]="searchInput.control"
                        ngbDatepicker 
                        #d="ngbDatepicker"
                        
                        >
                        <span class="bx bx-calendar-alt text-primary" (click)="d.toggle()"></span>

                    }
                  </div>
                </form>
              </div>
            </div>
        }
      }
    
   
    
      @if (searchNumber.show) {
        <div class="col-12 col-md-3 mb-3">
          <div class="mb-3">
            <form class="app-search d-lg-block">
              <div class="position-relative">
                <input 
                  type="search" 
                  class="form-control" 
                  placeholder="{{searchNumber.placeholder}}"
                  [formControl]="searchNumberSerie"
                >
                <span class="bx bx-search-alt"></span>
              </div>
            </form>
          </div>
        </div>
      }


      @if (dateRange.show) {
        <div class="col-12 col-md-3 mb-3">
          <div class="mb-3">
            <form class="app-search">
              <div class="position-relative">
                <input 
                  type="search" 
                  class="form-control date theme-default" 
                  name="daterange" 
                  autocomplete="off"
                  [placeholder]="dateRange.placeholder" 
                  [bsConfig]="dateRangeConfig" 
                  bsDaterangepicker
                  (bsValueChange)="filterRangeDates($event)" 
                />
                <span class="bx bx-calendar-alt"></span>
              </div>
            </form>
          </div>
        </div>
      }
    
      <div class="col-12 col-md-3 mb-3 text-center text-md-start">
        <div class="mb-3">
          <button class="btn btn-sm btn-primary rounded-circle right-bar-toggle m-1"
            (click)="showRightsidebar('columns')">
            <i class="bx bx-cog p-2" style="font-size: 2em;"></i>
          </button>
          <button class="btn btn-sm btn-primary rounded-circle right-bar-toggle m-1"
            (click)="showRightsidebar('filters')">
            <i class="bx bx-filter-alt p-2" style="font-size: 2em;"></i>
          </button>
          <button class="btn btn-sm btn-primary rounded-circle right-bar-toggle m-1"
            (click)="downloadCsv()">
            <i class="bx bx-download p-2" style="font-size: 2em;"></i>
          </button>
        </div>
      </div>
    </div>
    
   
       
    <br>
    <div class="table-wrapper">
      <ag-grid-angular #agGrid style="width: 100%; height: 100%;" class="ag-theme-quartz" *ngIf="showTable"
        [enableCellTextSelection]="true" [columnDefs]="filteredColumns" [defaultColDef]="_defaultColDef"
        [gridOptions]="_gridOptions" [pagination]="true" [paginationPageSize]="pageSize"
        (sortChanged)="onSortChanged($event)"
        [paginationPageSizeSelector]="pageSizeOptions" [localeText]="localeText" [rowSelection]="rowSelection"
        (gridReady)="onGridReady($event)"></ag-grid-angular>
    </div>

  </div>
</div>